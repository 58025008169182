import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { getClientKey } from "../actions/stripe/getClientKeyAction";
import Spinner from "react-bootstrap/Spinner";
import "../AutoPay/HomePages.css";

function HomePage_Pay() {
  const navigate = useNavigate();
  const location = useLocation();

  const [takeawayName, setTakeawayName] = useState(localStorage.getItem("takeaway_name") || "");
  const [Order_id, setOrder_id] = useState(null);
  const [apiKey, setApikey] = useState(null);
  const [orderDetails, setOrderDetails] = useState(null);
  const [currency, setCurrency] = useState({
    'code': 'GBP',
    'symbol': '£'
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch client and order details from query parameters
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const clientId = queryParams.get("client_id");
    const orderId = queryParams.get("orderId");
    localStorage.setItem("orderID",orderId);
    const encodedApiKey = queryParams.get("apiKey");
    localStorage.setItem("apikeY",encodedApiKey);


    if (!clientId || !orderId || !encodedApiKey) {
      alert("Please scan the code to proceed.");
      return;
    }

    const decodedApiKey = atob(encodedApiKey);
    const decodedOrderId = atob(orderId);

    setOrder_id(decodedOrderId);
    setApikey(decodedApiKey);

    const post = {
      shopId: clientId,
      action: "getClientApiKey",
    };
    getClientKey(post); // Assuming this function is defined elsewhere
  }, [location.search]);

  // Fetch order details
  useEffect(() => {
    const fetchOrderDetails = async () => {
      if (!Order_id || !apiKey) return;

      setLoading(true);
      const postData = {
        apiKey: apiKey,
        orderId: Order_id,
      };

      localStorage.setItem("orderId", Order_id);
      localStorage.setItem("apikey", apiKey);

      try {
        const response = await fetch("https://api-new.fmb.eposapi.co.uk/OrderById", {
          method: "POST",
          headers: {
            "Accept": "application/json",
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: JSON.stringify(postData),
        });

        if (!response.ok) throw new Error(`Error: ${response.status}`);

        const data = await response.json();
        setOrderDetails(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchOrderDetails();
  }, [Order_id, apiKey]);

  useEffect(() => {
    if(orderDetails?.data?.currency) {
      console.log(orderDetails?.data?.currency);
      
      setCurrency(orderDetails?.data?.currency);
    }
  }, [orderDetails]);

  if (loading) {
    return (
      <div className="container_pay">
        <div className="text-center mt-4">
          <Spinner animation="border" />
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="container_pay">
        <h2>Error</h2>
        <p>{error}</p>
      </div>
    );
  }

  const { Order_Details } = orderDetails?.data || {};
  const { order, item, CustomerDetails } = Order_Details || {};
  const extraDiscount = order?.extra_discount ? order.extra_discount.split(";")[1] : "0.00";

  const clickContinue = () => {
    const amount = order.total;
    if (amount && parseFloat(amount) >= 0.5) {
      const queryParams = new URLSearchParams(location.search);
      localStorage.setItem('amount',amount);
      navigate(`/payment?client_id=${queryParams.get("client_id")}`);
    } else {
      alert("Invalid amount. Cannot proceed with payment.");
    }
  };
  // Handle table details logic
  const isDineIn = order?.tableSeat && order?.tableCount;
  if (isDineIn) {
    localStorage.setItem("Dine", true);
  }

  return (
    <section>
      <div className="main-view1">
        <div className="container_pay">
          <div className="header">
            <h3 className="mt-2 p-3 text-center">{takeawayName}</h3>
            <p className="text-center fs-4">Payment Request Recite</p>
            <div className="d-flex justify-content-around">
              <div className="fs-5">Date: {orderDetails?.data?.Date}</div>
              <div className="fs-5 Order">Order ID: {Order_id}</div>
            </div>
          </div>
          <br />
          {CustomerDetails ? (
            <div className="container1">
              <h3>Customer Details</h3>
              <p>
                Name: {CustomerDetails.fname} {CustomerDetails.lname} <br />
                Mobile No: {CustomerDetails.phone} <br />
                Address: {CustomerDetails.dno}, {CustomerDetails.add1} {CustomerDetails.add2}, {CustomerDetails.postcode}
              </p>
            </div>
          ) : (
            <div className="container1">
              <h3>Table Details</h3>
              <p>
                Table Name: {order?.tableName || "N/A"} <br />
                No. of Persons: {order?.tableSeat || "0"}
              </p>
            </div>
          )}
          <div className="container1">
            <h3>Order Summary</h3>
            <div className="m-4">
              <table className="table table-responsive">
                <thead>
                  <tr>
                    <th colSpan={3}>Item Name</th>
                    <th className="pri">Price</th>
                  </tr>
                </thead>
                <tbody>
                  {item &&
                    item.map((itemData, index) => (
                      <tr key={index}>
                        <td colSpan={3} className="wrap-text">
                            {itemData.qty || 0} * {itemData.item_name}
                        </td>
                        <td  className="pri" >{currency.symbol}{itemData.price || "0.00"}</td>
                      </tr>
                    ))}
                  <tr className="bg">
                    <td colSpan={3} className="fs-6">SubTotal:</td>
                   
                    <td className="pri">{currency.symbol}{order?.sub_total || "0.00"}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="m-4">
              <table className="table table-responsive">
                <tbody>
                  {orderDetails?.data?.ServiceCharge_Amt  && (
                    <tr>
                      <td colSpan={4}>Service Charge:</td>
                      
                      <td className="pri">{currency.symbol}{orderDetails?.data?.ServiceCharge_Amt || "0.00"}</td>
                    </tr>
                  )}
                  {order?.delivery_charge > 0.0 && (
                    <tr>
                      <td colSpan={4}>Delivery Charge:</td>
                    
                      <td className="pri">{currency.symbol}{order?.delivery_charge || "0.00"}</td>
                    </tr>
                  )}
                  {(orderDetails?.data?.Discount_Amt > 0.0 || extraDiscount > 0.0) && (
                    <tr>
                      <td colSpan={4}>Discount:</td>
                      <td className="pri">-{currency.symbol}{orderDetails?.data?.Discount_Amt > 0.0 ? orderDetails?.data?.Discount_Amt   : extraDiscount}</td>
                    </tr>
                  )}
                  <tr className="bg">
                    <td className="fs-5" colSpan={4}>Total:</td>
                    <td className="pri fs-5">{currency.symbol}{order?.total || "0.00"}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          {((order?.status === 0  || order?.status === 5) || (order.tableSeat >= 1 && order.payment_status === false)) ? (
            <div className="container1 ">
              <button className="btn" onClick={clickContinue}>Pay {order.total}</button>
            </div>
          ) : (
            <div className="container1 ">
              <button className="btn" disabled>Payment Succeeded</button>
            </div>
          )}
        </div>
      </div>
    </section>
  );
}

export default HomePage_Pay;
