const baseurl = "https://api-dev.fusionkitchen.co.uk";
const keyurl = "https://api-dev.fmb.eposapi.co.uk";
const local  = "http://localhost:8080";
const liveurl = "https://api.fmb.eposapi.co.uk";
//Live
export const POSTPAYMENTINTENT = liveurl;
export const GETCLIENTKEY = liveurl;
export const INSERTPAYMENTAMOUNT = liveurl;
//dev
// export const POSTPAYMENTINTENT = keyurl;
// export const GETCLIENTKEY = keyurl;
// export const INSERTPAYMENTAMOUNT = keyurl;
//Local
// export const POSTPAYMENTINTENT = keyurl;
// export const GETCLIENTKEY = keyurl;
// export const INSERTPAYMENTAMOUNT = keyurl;