import { CLIENT_KEY_SUCCESS, CLIENT_KEY_FAILURE } from "../../actions/types";

const initialState = {
  clientkey: null,
  error: null,
};

export const clientKeyReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLIENT_KEY_SUCCESS:
      localStorage.setItem('takeaway_name',action.payload.data.takeaway_name);
      localStorage.setItem('currency_code',action.payload.data.currency.code);
      localStorage.setItem('currency_symbol',action.payload.data.currency.symbol);
      return {
        ...state,
        clientkey: action.payload,
        error: null,
      };
    case CLIENT_KEY_FAILURE:
      return {
        ...state,
        clientkey: null,
        error: action.payload,
      };
    default:
      return state;
  }
};
